
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainSection {
    position: relative;

    color: white;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: $bestridesharelawyers-before-uber-accident-color;

        background-position: bottom;
    }
}
